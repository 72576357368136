// @ts-nocheck
import { HTML_TITLE_SUFFIX } from "../config";

/**
 * @param title
 * @returns {string}
 */
export function htmlTitle(title) {
    return `${title} | ${HTML_TITLE_SUFFIX}`;
}
