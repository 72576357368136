// @ts-nocheck
import L from "leaflet";
import "./BigVessel";
import "./SmallVessel";
import "./VesselName";

import { VESSEL_COLOR_NAME, VESSEL_COLOR_NAME_BG, VESSEL_COLOR_NAME_BG_SELECTED, VESSEL_COLOR_NAME_SELECTED, VESSEL_BIG_ZOOM, vesselColorIndex, VESSEL_NAME_ZOOM } from "../map";
import { getOffscreenCanvas, initOffscreenCanvas } from "../vessel-offscreen-canvas";
import { VESSEL_VIS_NORMAL } from "../const";
import { country2CodeIndex } from "../vessel";

// Initialise the vessel offscreen canvas
initOffscreenCanvas();

export default class Vessel {

    constructor(latLng, renderer, leafletMap, {
        vesselId,
        selected,
        blurred,
        fishing,
        country,
        risk,
        name,
        vesselInstanceNumber,
        sog,
        zoom,
        analysisTypeId,
        vesselClickHandler,
        vesselHoverHandler,
        buoy = false,
        visibility = VESSEL_VIS_NORMAL,
        ori = 0,
        debugHideVesselNames = false,
        hideVesselNames = false,
    }) {
        this.latLng = latLng;
        this.renderer = renderer;
        this.leafletMap = leafletMap;
        this.labelAtBottom = false;

        // Vessel properties
        this.vesselId = vesselId;
        this.selected = selected;
        this.blurred = blurred;
        this.highlighted = false;
        this.fishing = fishing;
        this.country = country;
        this.risk = risk;
        this.name = name;
        this.vesselInstanceNumber = vesselInstanceNumber;
        this.sog = sog;
        this.zoom = zoom;
        this.ori = ori;
        this.analysisTypeId = analysisTypeId;
        this.vesselClickHandler = vesselClickHandler;
        this.vesselHoverHandler = vesselHoverHandler;
        this.buoy = buoy;
        this.visibility = visibility;
        this.debugHideVesselNames = debugHideVesselNames;
        this.hideVesselNames = hideVesselNames;

        this.updateColorIndex();
        this.updateCountry2Index();
        this.createVesselLayer();
    }

    updateColorIndex() {
        this.colorIndex = vesselColorIndex(this.selected, this.fishing, this.analysisTypeId, this.risk);
    }

    updateCountry2Index() {
        this.country2Index = country2CodeIndex(this.country);
    }

    updateNameColors() {
        this.nameColor = this.selected ? VESSEL_COLOR_NAME_SELECTED : VESSEL_COLOR_NAME;
        this.nameBgColor = this.selected ? VESSEL_COLOR_NAME_BG_SELECTED : VESSEL_COLOR_NAME_BG;
    }

    /**
     * Whether this should be rendered as a vessel with a country label (true) or simple vessel (false)
     * @param selected
     * @param zoom
     * @return {boolean}
     */
    shouldRenderBig(selected, zoom) {
        return selected || zoom >= VESSEL_BIG_ZOOM;
    }

    /**
     * Whether or not the vessel name should be rendered
     * @param {boolean} selected
     * @param {number} zoom
     * @returns {boolean}
     */
    shouldRenderName(selected, zoom) {
        return !this.hideVesselNames && !this.debugHideVesselNames && (selected || zoom >= VESSEL_NAME_ZOOM);
    }

    createNameLayerWithEvent() {
        this.vesselNameLayer = this.createNameLayer();
        this.vesselNameLayer.on("click", this.vesselClickHandler);
        this.vesselNameLayer.on("mouseover", this.vesselHoverHandler);
        this.vesselNameLayer.on("mouseout", this.vesselHoverHandler);
    }

    createVesselLayer() {
        if (this.buoy) {
            if (this.shouldRenderBig(this.selected, this.zoom)) {
                this.vesselLayer = this.createBigBuoy();
            } else {
                this.vesselLayer = this.createSmallBuoy();
            }
        } else if (this.shouldRenderBig(this.selected, this.zoom)) {
            this.vesselLayer = this.createBigVessel();
        } else {
            this.vesselLayer = this.createSmallVessel();
        }

        if (this.shouldRenderName(this.selected, this.zoom)) {
            this.updateNameColors();
            this.createNameLayerWithEvent();
        } else {
            this.removeNameLayerFromMap();
            this.vesselNameLayer = null;
        }

        this.vesselLayer.on("click", this.vesselClickHandler);
        this.vesselLayer.on("mouseover", this.vesselHoverHandler);
        this.vesselLayer.on("mouseout", this.vesselHoverHandler);
    }

    addToMap() {
        this.vesselLayer.addTo(this.leafletMap);
        if (this.vesselNameLayer) {
            this.vesselNameLayer.addTo(this.leafletMap);
        }
    }

    removeFromMap() {
        this.remove();
    }

    delete() {
        this.removeFromMap();
        this.vesselLayer = null;
        this.vesselNameLayer = null;
    }

    update({ latLng, risk, visibility, ori, sog, blurred, highlighted, hideVesselNames } = {}) {
        this.latLng = latLng;
        this.risk = risk;
        this.visibility = visibility;
        this.ori = ori;
        this.sog = sog;
        this.blurred = blurred;
        this.highlighted = highlighted;
        this.hideVesselNames = hideVesselNames;
        this.updateColorIndex();

        // Note: the implementation of update differs between BigVessel, SmallVessel and Buoy
        this.vesselLayer.update({
            latLng: this.latLng,
            colorIndex: this.colorIndex,
            blurred: this.blurred,
            highlighted: this.highlighted,
            visibility: this.visibility,
            ori: this.ori,
        });

        if (this.hideVesselNames) {
            this.removeNameLayerFromMap();
            return;
        }

        if (this.vesselNameLayer) {
            this.vesselNameLayer.update(this.latLng, this.visibility, this.blurred, this.highlighted);
            return;
        }

        if (this.shouldRenderName(this.selected, this.zoom)) {
            this.updateNameColors();
            this.createNameLayerWithEvent();
            this.vesselNameLayer.addTo(this.leafletMap);
        }
    }

    updateRisk(risk) {
        this.risk = risk;
    }

    updateAnalysisType(analysisTypeId) {
        if (analysisTypeId !== this.analysisTypeId) {
            this.analysisTypeId = analysisTypeId;
            this.updateColorIndex();
        }
    }

    createNameLayer() {
        return L.vesselName(this.name, this.latLng, this.renderer, {
            vesselId: this.vesselId,
            textColor: this.nameColor,
            bgColor: this.nameBgColor,
            visibility: this.visibility,
            blurred: this.blurred,
            highlighted: this.highlighted,
            vesselInstanceNumber: this.vesselInstanceNumber,
            buoy: this.buoy,
        });
    }

    createBigVessel() {
        return new L.BigVessel(this.latLng, this.colorIndex, this.renderer, getOffscreenCanvas(), {
            name: this.name,
            country: this.country,
            vesselId: this.vesselId,
            ori: this.ori,
            visibility: this.visibility,
            blurred: this.blurred,
            highlighted: this.highlighted,
            country2Index: this.country2Index,
            debugHideVesselNames: this.debugHideVesselNames,
        });
    }

    createSmallVessel() {
        return new L.SmallVessel(this.latLng, this.colorIndex, this.renderer, getOffscreenCanvas(), {
            colorIndex: this.colorIndex,
            vesselId: this.vesselId,
            visibility: this.visibility,
        });
    }

    createBigBuoy() {
        return new L.BigBuoy(this.latLng, this.colorIndex, this.renderer, getOffscreenCanvas(), {
            colorIndex: this.colorIndex,
            vesselId: this.vesselId,
            blurred: this.blurred,
            highlighted: this.highlighted,
        });
    }

    createSmallBuoy() {
        return new L.SmallBuoy(this.latLng, this.colorIndex, this.renderer, getOffscreenCanvas(), {
            colorIndex: this.colorIndex,
            vesselId: this.vesselId,
            blurred: this.blurred,
            highlighted: this.highlighted,
        });
    }

    refresh() {
        this.delete();
        this.updateColorIndex();
        this.createVesselLayer();
        this.addToMap();
    }

    select() {
        if (!this.selected) {
            this.selected = true;
            this.refresh();
        }
    }

    deSelect() {
        if (this.selected) {
            this.selected = false;
            this.refresh();
        }
    }

    highlight() {
        if (!this.highlighted) {
            this.highlighted = true;
            this.refresh();
        }
    }

    deHighlight() {
        if (this.highlighted) {
            this.highlighted = false;
            this.refresh();
        }
    }

    updateZoom(zoom) {
        if (this.zoom === zoom) {
            return;
        }
        if ((this.shouldRenderBig(this.selected, this.zoom) !== this.shouldRenderBig(this.selected, zoom))
            || this.shouldRenderName(this.selected, this.zoom) !== this.shouldRenderName(this.selected, zoom)
        ) {
            const onMap = this.isOnMap();

            this.zoom = zoom;
            this.delete();
            this.createVesselLayer();

            // Only re-add to the map if it was already on the map
            if (onMap) {
                this.addToMap();
            }
        } else {
            this.zoom = zoom;
        }
    }

    removeNameLayerFromMap() {
        if (this.vesselNameLayer) {
            this.leafletMap.removeLayer(this.vesselNameLayer);
            this.vesselNameLayer = null;
        }
    }

    remove() {
        this.leafletMap.removeLayer(this.vesselLayer);
        this.removeNameLayerFromMap();
    }

    isOnMap() {
        return this.leafletMap.hasLayer(this.vesselLayer);
    }

    isSelected() {
        return this.selected;
    }

    allPxBounds() {
        const bounds = [this.vesselLayer._pxBounds];
        if (this.vesselNameLayer) {
            bounds.push(this.vesselNameLayer._pxBounds);
        }
        return bounds;
    }

    labelPosBottom() {
        if (this.vesselNameLayer) {
            this.labelAtBottom = true;
            this.vesselNameLayer.labelPosBottom();
            this.vesselNameLayer.redraw();
        }
    }

    labelPosReset() {
        if (this.vesselNameLayer) {
            this.labelAtBottom = false;
            this.vesselNameLayer.labelPosReset();
            this.vesselNameLayer.redraw();
        }
    }

    isLabelAtBottom() {
        return this.labelAtBottom;
    }

    getLeafletMap() {
        return this.leafletMap;
    }
}
