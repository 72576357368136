// @ts-nocheck
import { groupAcquisitions } from "../../acquisition";
import Acquisition from "../Acquisition";
import ActivityBase from "./ActivityBase";

export default class ActivitySatelliteAcquisition extends ActivityBase {
    static name = "ActivitySatelliteAcquisition";

    /**
     * @returns {Object}
     */
    get data() {
        return this._params.satelliteAcquisitions;
    }

    /**
     * @returns {number}
     */
    get firstTs() {
        return this.data.firstTs;
    }

    /**
     * @returns {number}
     */
    get lastTs() {
        return this.data.lastTs;
    }

    /**
     * @returns {string}
     */
    get provider() {
        return this.data.provider;
    }

    /**
     * @returns {(AcquisitionGroup|Acquisition)[]}
     */
    get acquisitions() {
        if (this._acquisitions) {
            return this._acquisitions;
        }

        // Convert the acquisitions to AcquisitionGroup objects
        this._acquisitions = groupAcquisitions(this.data.satelliteAcquisition.map((acq) => new Acquisition(acq)));
        return this._acquisitions;
    }

    /**
     * @returns {string}
     */
    get uuid() {
        return `ActivitySatelliteAcquisition-${this.provider}-${this.firstTs}-${this.lastTs}}`;
    }
}
