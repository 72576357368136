// @ts-nocheck
import * as h3 from "h3-js";
import { H3_1, H3_2, H3_3, SKIM_DURATION_10M, SKIM_DURATION_1H, SKIM_DURATION_24H, SKIM_VALID_VALUES } from "./const";

/**
 * Return the start of day timestamp from cell key
 * @param {string} key
 * @returns {string}
 */
export function getH3DayStartFromKey(key) {
    return key.substring(16, 26);
}

/**
 * Extract h3id from cache key
 * @param {string} key
 * @returns {string}
 */
export function getH3IndexFromKey(key) {
    return key.substring(0, 15);
}

// eslint-disable-next-line security/detect-non-literal-regexp
const getSkimRegex = new RegExp(`-(${SKIM_VALID_VALUES.map((v) => `${v}`).join("|")})-`);

export function getSkimFromKey(key) {
    return key.match(getSkimRegex)[1];
}

/**
 * @param {string} key
 * @param {number} skim
 * @returns {string}
 */
export function addSkimToKey(key, skim) {
    return key.slice(0, 38) + skim + key.slice(37);
}

/**
 * @param {string[]} keys
 * @return {string[]}
 */
export function getH3IndexesFromKeys(keys) {
    return keys.map((key) => getH3IndexFromKey(key));
}

/**
 * Generate h3 cache key
 * @param {string} h3Id
 * @param {number} startTimeUTC
 * @param {number} endTimeUTC
 * @param {number} skim
 * @param {string} filterSetKey
 * @returns {string}
 */
export function h3Key(h3Id, startTimeUTC, endTimeUTC, skim, filterSetKey, filterSetLastEvaluatedAt) {
    // Use + operator here as it's much faster than template literals
    return h3Id + "-" + startTimeUTC + "-" + endTimeUTC + "-" + skim + "-" + filterSetKey + "-" + filterSetLastEvaluatedAt; // eslint-disable-line
}

// Pre-generate the regexp so h3KeyStripSkim is extra speedy
// Generates a RegExp that looks like this: (?:-600-|-3600-|-86400-)
// eslint-disable-next-line security/detect-non-literal-regexp
const skimSearchString = new RegExp(`(?:${SKIM_VALID_VALUES.map((v) => `-${v}-`).join("|")})`);

/**
 * Strips skim from a cell key, used for getVesselsInCell caching where skim is irrelevant
 * @param {string} cellKey
 * @returns {string}
 */
export function h3KeyStripSkim(cellKey) {
    return cellKey.replace(skimSearchString, "-");
}

/**
 * Same as above but with no skim, duplicating for performance
 * @param {string} h3Id
 * @param {number} startTimeUTC
 * @param {number} endTimeUTC
 * @param {string} filterSetKey
 * @returns {string}
 */
export function h3KeyNoSkim(h3Id, startTimeUTC, endTimeUTC, filterSetKey, filterSetLastEvaluatedAt) {
    // Use + operator here as it's much faster than template literals
    return h3Id + "-" + startTimeUTC + "-" + endTimeUTC + "-" + filterSetKey + "-" + filterSetLastEvaluatedAt; // eslint-disable-line
}

/**
 * Helper for deciding when to display h3 cells labels when debugH3 is enabled
 * @param zoom
 * @param h3Res
 * @returns {boolean}
 */
export function h3DebugLabels(zoom, h3Res) {
    if (h3Res === H3_3 && zoom >= 7) {
        return true;
    }
    if (h3Res === H3_2 && zoom >= 6) {
        return true;
    }
    if (h3Res === H3_1 && zoom >= 4) {
        return true;
    }
    return false;
}

/**
 * @param {number} zoom
 * @returns {number}
 */
export function h3ResFromZoom(zoom) {
    if (zoom >= 9) { // 9-20
        return 3;
    }
    if (zoom >= 6) { // 6, 7, 8
        return 2;
    }
    return 1;
}

/**
 * @param {number} zoom
 * @returns {number}
 */
export function h3SkimFromZoom(zoom) {
    // 9-20
    if (zoom >= 9) {
        return SKIM_DURATION_10M;
    }

    // 6, 7, 8
    if (zoom >= 6) {
        return SKIM_DURATION_1H;
    }

    // 1, 2, 3, 4, 5
    return SKIM_DURATION_24H;
}

/**
 * Return ALL cells for a given resolution
 * @param {number} h3Res
 * @returns {H3Index[]|string[]}
 */
export function getAllH3Cells(h3Res) {
    const h3Indexes = h3.getRes0Cells();
    if (h3Res === 0) {
        return h3Indexes;
    }
    return h3Indexes.flatMap((h3Index) => h3.cellToChildren(h3Index, h3Res));
}
