// @ts-nocheck
// Disable, functions are in line with Leaflet functions
/* eslint-disable func-names */
import L from 'leaflet';
import "./BoxBase";

/**
 * Allows holding shift then drawing a box to add multiple vessels to the current selection
 */
L.BoxSelect = L.BoxBase.extend({

    cssBoxClass: "leaflet-zoom-box leaflet-zoom-box--select",
    cursorClass: "leaflet-crosshair",

    _checkValidEvent: function (e) {
        return e.shiftKey && (e.which === 1 || e.button === 1);
    },

    _boxComplete: function (bounds) {
        // Send the bounds to a callback if one has been specified on the map instance
        if (this._map.options.boxSelectCb) {
            this._map.options.boxSelectCb(bounds);
        }
    },
});
