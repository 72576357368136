// @ts-nocheck
// Disable, functions are in line with Leaflet functions
/* eslint-disable func-names */
import L from 'leaflet';
import "./BoxBase";

/**
 * Allows holding ctrl then drawing a box zoom to that area. Copies Leaflet BoxZoom functionality
 * but with the ctrl key because you can't alter the key binding in BoxZoom :/
 */
L.BoxZoomCtrl = L.BoxBase.extend({

    cssBoxClass: "leaflet-zoom-box",
    cursorClass: "leaflet-zoom",

    _checkValidEvent: function (e) {
        return e.ctrlKey && (e.which === 1 || e.button === 1);
    },

    _boxComplete: function (bounds) {
        this._map
            .fitBounds(bounds)
            .fire('boxzoomend', { boxZoomBounds: bounds });
    },
});
