// @ts-nocheck
// Disable, functions are in line with Leaflet functions
/* eslint-disable func-names */
import * as L from "leaflet";

/**
 * Designed to be inherited from, contains all the necessary functions to interaction with Leaflet Canvas
 */
const BasicLayer = L.Path.extend({

    options: {
        interactive: true,
        bubblingMouseEvents: false,
        stroke: false,
        fill: true,
    },

    initialize: function (latLng, renderer, options = {}) {
        this._latlng = L.latLng(latLng);
        options.renderer = renderer;
        L.Util.setOptions(this, options);
    },

    // Called by parent Path.js in _reset
    _project: function () {
        this._point = this._map.latLngToLayerPoint(this._latlng);
        this._updateBounds();
    },

    // Called by parent Path.js in _reset
    _update: function () {
        if (this._map) {
            this._updatePath();
        }
    },

    // Whether or not the layer is empty
    _empty: function () {
        return !this._latlng;
    },

    // Whether or not this layer contains the passed point
    _containsPoint: function (point) {
        return this._pxBounds.contains(point);
    },

    _updatePath: function () {
        this.updatePath();
    },

    _updateBounds: function () {
        this.updateBounds();
    },

    updatePath: function () {
        throw new Error("Must implement updatePath");
    },

    updateBounds: function () {
        throw new Error("Must implement updateBounds");
    },

    update: function () { // eslint-disable-line no-unused-vars
        throw new Error("Must implement update");
    },
});

L.basicLayer = function shortcut(latLng, colorIndex, renderer, offscreenCanvas, options) {
    return new BasicLayer(latLng, colorIndex, renderer, offscreenCanvas, options);
};
L.BasicLayer = BasicLayer;
