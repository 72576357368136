// @ts-nocheck
import { createSelector } from "reselect";
import { getAoiId, getAoiFilterType, getSelectedDataEndTs, getSelectedDataStartTs, getCurrentOrganisationId } from "../../redux/derived-data";
import { aoiFilterTypeInside, aoiIsAnywhereOrViewport, isUserAoi } from "../aoi";
import VesselFilterTimeBound from "./VesselFilterTimeBound";
import { FILTER_SET_NAMES } from "../const";

export default class VesselFilterInAoi extends VesselFilterTimeBound {
    name = FILTER_SET_NAMES.FILTER_BY_INSIDE_AOI;

    /**
     * fromState is a static factory method for generating and caching VesselFilterInAoi objects
     * based on the current state of the Redux store.
     * @param {Object} state
     * @returns {VesselFilterInAoi|null}
     * @example
     * const filter = VesselFilterInAoi.fromState(state);
     */
    static fromState = createSelector(
        [
            getAoiFilterType,
            getAoiId,
            getSelectedDataStartTs,
            getSelectedDataEndTs,
            getCurrentOrganisationId,
        ],
        (aoiFilterType, aoiId, startTs, endTs, orgId) => {
            if (!aoiIsAnywhereOrViewport(aoiId) && !isUserAoi(aoiId) && aoiFilterTypeInside(aoiFilterType)) {
                return new VesselFilterInAoi(aoiId, startTs, endTs, orgId);
            }

            return null;
        },
    );

    /**
     * @param {number} aoiId
     * @param {number} startTs
     * @param {number} endTs
     * @param {number} orgId
     * @param {boolean} exclude
     */
    constructor(aoiId, startTs, endTs, orgId, exclude = false) {
        super(startTs, endTs, null, exclude);
        this.aoiId = aoiId;
        this.orgId = orgId;
    }

    /**
     * @returns {{name: string, startTime: number, endTime: number, orgID: number, aoiIDs: number[]}}
     */
    getFilter() {
        return {
            name: this.name,
            startTime: this.startTs,
            endTime: this.endTs,
            orgID: this.orgId,
            aoiIDs: [this.aoiId],
        };
    }

    /**
     * @param {Object} data
     * @returns {VesselFilterInAoi}
     */
    static deserialize(data) {
        return new VesselFilterInAoi(data.aoiIDs[0], data.startTime, data.endTime, data.orgID, Boolean(data.exclude));
    }
}
