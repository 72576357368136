// @ts-nocheck
import { createSelector } from "reselect";
import VesselFilterBase from "./VesselFilterBase";
import { FILTER_SET_NAMES, OPERATORS, VESSEL_TYPE_ID_BUOY, VESSEL_TYPE_ID_GHOST } from "../const";
import { getBuoysVisible, getDebugShowGhosts, getFilterVesselType } from "../../redux/derived-data";
import { emptyObject } from "../utils";
import { getVesselTypeAliasIds } from "../vessel-utils";

export default class VesselFilterType extends VesselFilterBase {
    name = FILTER_SET_NAMES.FILTER_BY_VESSEL_TYPE;

    /**
     * fromState is a static factory method for generating and caching VesselFilterType objects
     * based on the current state of the Redux store.
     * @param {Object} state
     * @returns {VesselFilterType[]|null}
     * @example
     * const filter = VesselFilterType.fromState(state);
     */
    static fromState = createSelector(
        [
            getFilterVesselType,
            getBuoysVisible,
            getDebugShowGhosts,
        ],
        (vesselTypeFilter, buoysVisible, debugShowGhosts) => {
            const filters = [];

            if (!buoysVisible || !debugShowGhosts) {
                const types = [];

                if (!buoysVisible) {
                    types.push(VESSEL_TYPE_ID_BUOY);
                }

                if (!debugShowGhosts) {
                    types.push(VESSEL_TYPE_ID_GHOST);
                }

                // Exclude buoys and/or ghost vessels
                filters.push(new VesselFilterType(types, true));
            }

            if (!emptyObject(vesselTypeFilter)) {
                let vesselTypes = vesselTypeFilter.vesselTypes;

                // Include buoys in list of included vessel types if they've been turned on
                if (buoysVisible && vesselTypeFilter.op !== OPERATORS.NOT_IN) {
                    vesselTypes = vesselTypes.concat(VESSEL_TYPE_ID_BUOY);
                }

                // Add any vessel type aliases
                const aliases = vesselTypes.flatMap((vesselTypeId) => {
                    return getVesselTypeAliasIds(vesselTypeId);

                    // Remove nulls
                }).filter((v) => v);

                vesselTypes = vesselTypes.concat(aliases);

                filters.push(new VesselFilterType(vesselTypes, vesselTypeFilter.op === OPERATORS.NOT_IN));
            }

            return filters;
        },
    );

    /**
     * @param {number[]} vesselTypes
     * @param {boolean} exclude
     */
    constructor(vesselTypes, exclude = false) {
        super(exclude);
        this.vesselTypes = vesselTypes;
    }

    /**
     * @returns {{name: string, vesselTypes}}
     */
    getFilter() {
        return {
            name: this.name,
            exclude: this.exclude,
            vesselTypeIDs: this.vesselTypes,
        };
    }

    /**
     * @param {Object} data
     * @returns {VesselFilterType}
     */
    static deserialize(data) {
        return new VesselFilterType(data.vesselTypeIDs, Boolean(data.exclude));
    }
}
