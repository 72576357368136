import { LOGIN_PATH } from "../config";
import { shutDownIntercom } from "./intercom";

/**
 * Immediately redirect the browser to the login page with the current path specified as the return path
 */
export function redirectToLogin() {
    shutDownIntercom();

    // Check redirect doesn't set the return path as the login page. Can happen if an existing request returns a 401
    const returnPath = window.location.pathname === LOGIN_PATH ?
        "" :
        `?r=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`;

    window.location.href = `${LOGIN_PATH}${returnPath}`;
}
