// @ts-nocheck
import AlertRule from "./classes/AlertRule";

/**
 * Get the key for a geofence event group.
 * Used to group alert notifications in the vessel list and csv download.
 * @param {Object} event
 * @returns {string}
 */
export function getGeofenceEventGroupKey(event) {
    const alertRule = AlertRule.deserialize(event.geof.alertRule);
    return `aoiId:${alertRule.getAoiId()}|alertRuleType:${alertRule.getType()}`;
}

/**
 * Get the key for an alert event group.
 * Used to group alert notifications in the vessel list and csv download.
 * @param {Object} event
 * @returns {string}
 */
export function getAlertEventGroupKey(event) {
    const alertRule = AlertRule.deserialize(event.alert.alertRule);
    return `alertRuleId:${alertRule.getId()}`;
}

/**
 * Get the aoiId from a geofence event group key
 * @param {string} key
 * @returns {number|null}
 */
export function getAoiIdFromGeofenceEventGroupKey(key) {
    const matches = key.match(/aoiId:([^|]+)/);
    return matches ? Number(matches[1]) : null;
}

/**
 * Get the type from a geofence event group key (e.g. ENTR, EXIT)
 * @param {string} key
 * @returns {string|null}
 */
export function getTypeFromGeofenceEventGroupKey(key) {
    const matches = key.match(/alertRuleType:([^|]+)/);
    return matches ? matches[1] : null;
}

/**
 * Returns true if the key is a geofence event group key
 * @param {string} key
 * @returns {boolean}
 */
export function isGeofenceEventGroupKey(key) {
    return key.startsWith("aoiId:");
}
