// @ts-nocheck
import { STATUS } from "./const";

export function isComplete(status) {
    return status === STATUS.COMPLETE;
}

export function isLoading(status) {
    return status === STATUS.LOADING;
}

export function isSaving(status) {
    return status === STATUS.SAVING;
}
