// @ts-nocheck
/* eslint-disable import/no-unused-modules */
import { FILTER_SET_NAMES } from "../const";
import VesselFilterBase from "./VesselFilterBase";

export default class VesselFilterIds extends VesselFilterBase {
    name = FILTER_SET_NAMES.FILTER_BY_VESSEL_ID;

    /**
     * @param {number[]} vesselIds
     * @param {boolean} exclude
     */
    constructor(vesselIds, exclude = false) {
        super(exclude);
        this.vesselIds = vesselIds;
    }

    /**
     * @returns {{name: string, exclude: boolean, vesselIds: number[]}}
     */
    getFilter() {
        return {
            name: this.name,
            exclude: this.exclude,
            vesselIDs: this.vesselIds,
        };
    }

    /**
     * @param {Object} data
     * @returns {VesselFilterIds}
     */
    static deserialize(data) {
        return new VesselFilterIds(data.vesselIDs, Boolean(data.exclude));
    }
}
