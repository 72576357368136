// @ts-nocheck
import L from "leaflet";
import "./VesselLayer";
import { SMALL_VESSEL_RADIUS } from "../map";
import { RETINA_SCALE } from "../../config";
import { SMALL_VESSEL_OUTLINE_TRANSSHIPMENT_Y, SMALL_VESSEL_OUTLINE_Y, SMALL_VESSEL_Y, smallVesselX } from "../vessel-offscreen-canvas";
import { VESSEL_VIS_AWOL, VESSEL_VIS_AWOL_TRANSSHIPMENT, VESSEL_VIS_HIDDEN } from "../const";

const SmallVessel = L.VesselLayer.extend({

    /**
     * Update this layer and redraw
     * @param {object} param
     * @param {latLng} param.latLng
     * @param {number} param.colorIndex
     * @param {bool} param.blurred
     * @param {number} param.visibility
     */
    update({ latLng, colorIndex, blurred, visibility }) {
        this._latlng = latLng;
        const changed = this.changed(colorIndex, blurred, visibility);
        if (changed) {
            this.visibility = visibility;
            this.setColorIndex(colorIndex);
            this.blurred = blurred;
            this.updateSxSy();
        }
        this.redraw();
    },

    updateBounds() {

        // If the vessel name is hidden, give it 0 bounds so it can't be clicked on
        if (this.visibility === VESSEL_VIS_HIDDEN) {
            this._pxBounds = L.bounds([0, 0], [0, 0]);
            return;
        }

        const t = this._clickTolerance();
        const p = [SMALL_VESSEL_RADIUS + t, SMALL_VESSEL_RADIUS + t];
        this._pxBounds = new L.Bounds(this._point.subtract(p), this._point.add(p));
    },

    updatePath() {
        this._renderer.updateSmallVessel(this);
    },

    getSx() {
        return smallVesselX(this.colorIndex) * RETINA_SCALE;
    },

    getSy() {
        if (this.visibility === VESSEL_VIS_AWOL) {
            return SMALL_VESSEL_OUTLINE_Y * RETINA_SCALE;
        }
        if (this.visibility === VESSEL_VIS_AWOL_TRANSSHIPMENT) {
            return SMALL_VESSEL_OUTLINE_TRANSSHIPMENT_Y + RETINA_SCALE;
        }
        return SMALL_VESSEL_Y * RETINA_SCALE;
    },

});

// Add a shortcut to the global Leaflet object
L.smallVessel = function shortcut(latLng, colorIndex, renderer, offscreenCanvas, options) {
    return new SmallVessel(latLng, colorIndex, renderer, offscreenCanvas, options);
};
L.SmallVessel = SmallVessel;
