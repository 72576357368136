// @ts-nocheck
import { createSelector } from "reselect";
import VesselFilterBase from "./VesselFilterBase";
import { getSelectedDataEndTs, getSelectedDataStartTs } from "../../redux/derived-data";
import { aoiIsAnywhereOrViewport } from "../aoi";
import { endOfDayToMidnight } from "../timeutils";
import { AOI_ID_ANYWHERE } from "../../config";
import { FILTER_SET_NAMES } from "../const";

export default class VesselFilterTimeBound extends VesselFilterBase {
    name = FILTER_SET_NAMES.FILTER_BY_TIME;

    /**
     * fromState is a static factory method for generating and caching VesselFilterTimeBound objects
     * based on the current state of the Redux store.
     * @param {Object} state
     * @returns {VesselFilterTimeBound[]|null}
     * @example
     * const filter = VesselFilterTimeBound.fromState(state);
     */
    static fromState = createSelector(
        [
            (state) => {
                // Treat VIEWPORT the same as ANYWHERE so switching between them in the UI
                // doesn't trigger an update, VIEWPORT filtering is done in the client.
                return aoiIsAnywhereOrViewport(state.aoi.id) ? AOI_ID_ANYWHERE : state.aoi.id;
            },
            getSelectedDataStartTs,
            getSelectedDataEndTs,
        ],
        (aoiId, startTs, endTs) => {
            // Only use this filter if there is no area selected or we're viewing _On screen_ vessels
            if (aoiIsAnywhereOrViewport(aoiId)) {
                return [new VesselFilterTimeBound(startTs, endTs)];
            }
            return null;
        },
    );

    /**
     * @param {number} startTs
     * @param {number} endTs
     * @param {number|null} duration
     * @param {boolean} exclude
     */
    constructor(startTs, endTs, duration = null, exclude = false) {
        super(exclude);
        this.startTs = startTs;
        this.endTs = endTs === -1 ? endTs : endOfDayToMidnight(endTs); // Round 23:59:59 up to 00:00:00
        this.duration = duration;
    }

    /**
     * @returns {{name: string, endTs: number, startTs: number}}
     */
    getFilter() {
        return {
            name: this.name,
            startTime: this.startTs,
            endTime: this.endTs,
            duration: this.duration,
            exclude: this.exclude,
        };
    }

    /**
     * @param {Object} data
     * @returns {VesselFilterTimeBound}
     */
    static deserialize(data) {
        return new VesselFilterTimeBound(data.startTime, data.endTime, data.duration, Boolean(data.exclude));
    }
}
