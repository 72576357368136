// @ts-nocheck
import { CSV_VOID_3, csvToBlob, dateTimeTzCols, downloadBlob } from "./download-utils";
import { roleTitle } from "./organisation";
import { dateTzFilename } from "./timeutils";
import { isAdmin } from "./user";
import { escCsvStr, utcTs } from "./utils";

/**
 * Convert a user's roles to a CSV string.
 * @param {Object} user - Enterprise user.
 * @returns {string} CSV string.
 */
function userOrgAndGroupsCsvStr(user) {
    return user.roles.map((role) => {
        return (`${role.organisation.name} (${roleTitle(role.organisation, isAdmin(role.capabilityBitmap), { verbose: true })})`);
    }).join("\n");
}

/**
 * Download a CSV file with the list of enterprise users.
 * @param {Object[]} users - List of enterprise users.
 */
export function downloadEnterpriseUsers(users) {
    const rows = [
        [
            '"First name"',
            '"Last name"',
            '"Email"',
            '"Organisation & groups"',
            '"2FA enabled"',
            '"Login count, last 90d"',
            '"Last login time"',
            '"Timezone"',
            '"Offset"',
        ],
    ];

    users.forEach((user) => {
        rows.push([
            `"${escCsvStr(user.simpleUser.firstName)}"`,
            `"${escCsvStr(user.simpleUser.lastName)}"`,
            `"${escCsvStr(user.simpleUser.email)}"`,
            `"${escCsvStr(userOrgAndGroupsCsvStr(user))}"`,
            `"${escCsvStr(`${user.mfaEnabled ? "Yes" : "No"}`)}"`,
            `"${user.loginCount.toLocaleString()}"`,
            ...(user.lastLoginAt ? dateTimeTzCols(user.lastLoginAt) : CSV_VOID_3),
        ]);
    });

    downloadBlob(csvToBlob(
        rows,
        `starboard-enterprise-user-list-${dateTzFilename(utcTs(), { includeTime: true })}.csv`,
    ));
}
