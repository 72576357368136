// @ts-nocheck
/* eslint-disable func-names */
import * as L from 'leaflet';

/**
 * A canvas element with no mouse interaction
 */
const NonInteractiveCanvas = L.Canvas.extend({
    _onMouseMove: function () {
        return false;
    },

    _onClick: function () {
        return false;
    },
});

// Add a shortcut to the global Leaflet object
L.nonInteractiveCanvas = function shortcut(options) {
    return new NonInteractiveCanvas(options);
};
L.NonInteractiveCanvas = NonInteractiveCanvas;
