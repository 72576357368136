// @ts-nocheck
import * as L from "leaflet";
import "./BasicLayer";

const EventMarker = L.BasicLayer.extend({

    initialize(latLng, markerType, w, h, renderer, options) {
        L.BasicLayer.prototype.initialize.call(this, latLng, renderer, options);
        this.markerType = markerType;
        this.w = w;
        this.h = h;
        this.blurred = options.blurred;
    },

    /**
     * Used for click detection and clipping (I think)
     */
    updateBounds() {
        const t = this._clickTolerance();
        const p = [this.w + t, this.h + t];
        this._pxBounds = new L.Bounds(this._point.subtract(p), this._point.add(p));
    },

    updatePath() {
        this._renderer.updateSvg(this);
    },

    /**
     * Update this layer and redraw
     * @param latLng
     */
    update(latLng) {
        this._latlng = L.latLng(latLng);
        this.redraw();
    },

    getType() {
        return this.markerType;
    },

    getWidth() {
        return this.w;
    },

    getHeight() {
        return this.h;
    },
});

// Add a shortcut to the global Leaflet object
L.eventMarker = function shortcut(latLng, markerType, w, h, renderer, options) {
    return new EventMarker(latLng, markerType, w, h, renderer, options);
};
