// @ts-nocheck
import intersection from "lodash.intersection";
import moment from "moment";
import { getTimezones } from "./api";
import mapIANAToGenericTz from "../data/map-iana-to-generic-tz.json";
import genericTzData from "../data/generic-tz-data.json";
import { TZ_NZ, TZ_UTC } from "../config";

function offsetToInt(offset) {
    return parseInt(offset.replace(":", ""), 10);
}

function sortByAbbrAndName(timezone1, timezone2) {
    return timezone1.abbrAndName.localeCompare(timezone2.abbrAndName);
}

/**
 * Retrieve generic timezone string for a given IANA timezone
 * @param {String} nameIANA
 * @returns {String}
 */
export function tzNameGeneric(nameIANA) {
    return mapIANAToGenericTz[nameIANA];
}

/**
 * Retrieve generic timezone data for given IANA timezone string
 * @param {String} nameIANA
 * @returns {Object}
 */
export function tzGenericData(nameIANA) {
    return genericTzData.find((genericData) => {
        return mapIANAToGenericTz[nameIANA] === genericData.nameGeneric;
    });
}

/**
 * Get a representation of the selected timezone depending on whether it has abbreviations etc e.g:
 * NZST, NZDT (UTC +12:00, +13:00)
 * Acre Standard Time (UTC -05:00)
 * UTC +06:00
 * @param {Object} tzData
 * @returns {String}
 */
export function getTimezoneDisplay(tzData) {
    const tzAbbr = `${tzData.abbr ? `${tzData.abbr}${tzData.abbrDST ? `, ${tzData.abbrDST}` : ''}` : tzData.nameGeneric}`;
    const tzOffset = `${tzData.offset}${tzData.offsetDST ? `, ${tzData.offsetDST}` : ''}`;

    if (tzAbbr === 'UTC') {
        return 'UTC';
    }

    if (tzData.nameGeneric.startsWith('UTC')) {
        return tzData.nameGeneric;
    }

    return `${tzAbbr} (UTC ${tzOffset})`;
}

/**
 * Run this if you need to update generic-tz-data.json and map-iana-to-generic-tz.json. Make
 * sure you're using latest version of Chrome as longGeneric timezone names have only recently
 * shipped: https://www.chromestatus.com/feature/4506375298220032
 */
// eslint-disable-next-line import/no-unused-modules
export async function printTimezoneData() {
    const momentTimezoneNames = moment.tz.names();
    const serverTimezoneNames = (await getTimezones()).map((timezone) => timezone.name);

    // We only want timezones that both the server and moment.js support
    const timezones = intersection(momentTimezoneNames, serverTimezoneNames);

    const ianaToGeneric = {};
    const genericData = [];

    timezones.forEach((nameIANA) => {
        const longGenericFormat = new Intl.DateTimeFormat('en-US', { timeZone: nameIANA, timeZoneName: 'longGeneric' });
        let nameGeneric = longGenericFormat.formatToParts().find((part) => part.type === 'timeZoneName').value;

        const dateDec = new Date(2021, 11, 1);
        const dateJun = new Date(2021, 5, 1);

        const abbrDec = moment(dateDec).tz(nameIANA).format('z');
        const abbrJun = moment(dateJun).tz(nameIANA).format('z');
        const offsetDec = moment(dateDec).tz(nameIANA).format('Z');
        const offsetJun = moment(dateJun).tz(nameIANA).format('Z');

        let abbr = abbrDec;
        let abbrDST = abbrJun !== abbrDec ? abbrJun : null;
        let offset = offsetDec;
        let offsetDST = offsetJun !== offsetDec ? offsetJun : null;

        // Order offsets and abbreviations correctly
        if (offsetDST && offsetToInt(offset) > offsetToInt(offsetDST)) {
            abbr = abbrJun;
            abbrDST = abbrDec;
            offset = offsetJun;
            offsetDST = offsetDec;
        }

        // We rely on the assumption that Chrome makes sure any IANA timezones that share the same
        // generic name also share the same UTC offsets and abbreviations. This is true except for
        // these four, that for some reason are given "GMT +0{x}:00" generic names even though they
        // have daylight savings offsets as well as standard offsets. These are fixed in the
        // corrections below:
        const corrections = {
            CET: 'Central European Time',
            EET: 'Eastern European Time',
            WET: 'Western European Time',
            MET: 'Middle European Time',
        };

        if (corrections[nameIANA]) {
            nameGeneric = corrections[nameIANA];
        }

        // Replace any instance of GMT with UTC
        if (['GMT', 'Greenwich Mean Time'].includes(nameGeneric)) {
            nameGeneric = 'Coordinated Universal Time';
            abbr = 'UTC';
        }

        // We don't want to show abbreviations if they're just numbers
        if (abbr.startsWith('+') || abbr.startsWith('-')) {
            abbr = null;
        }

        if (abbrDST?.startsWith('+') || abbrDST?.startsWith('-')) {
            abbrDST = null;
        }

        // Change anything that starts with GMT to start with UTC.
        if (nameGeneric?.startsWith('GMT')) {
            nameGeneric = nameGeneric.replace(/^GMT/, 'UTC ');
            abbr = null;
        }

        if (abbr === 'GMT' && offset === '+00:00') {
            abbr = 'UTC';
        }

        if (abbrDST === 'GMT' && offsetDST === '+00:00') {
            abbrDST = 'UTC';
        }

        // Store a mapping of the IANA timezone string to its generic timezone string
        ianaToGeneric[nameIANA] = nameGeneric;

        // If we haven't seen this timezone's generic name before then add it to the list of generic
        // names and map it to the IANA timezone, abbreviations and offsets.
        if (!genericData.find((tzData) => tzData.nameGeneric === nameGeneric)) {

            // This occasionally results in an unobvious IANA timezone being the 'representative' for
            // the generic timezone. So here are a few overrides to match up them up with more
            // obvious IANA timezones.
            if (nameGeneric === 'New Zealand Time') {
                nameIANA = TZ_NZ; // Otherwise it's Antarctica/McMurdo
            }

            if (nameGeneric === 'Coordinated Universal Time') {
                nameIANA = TZ_UTC; // Otherwise it's Africa/Abidjan
            }

            if (nameGeneric === 'Eastern Australia Time') {
                nameIANA = 'Australia/Sydney'; // Otherwise it's Antarctica/Macquarie
            }

            genericData.push({
                abbrAndName: `${abbr ? `${abbr}${abbrDST ? `, ${abbrDST}` : ''} – ` : ''}${nameGeneric}`,
                nameGeneric: nameGeneric,
                nameIANA: nameIANA,
                offset: offset,
                offsetDST: offsetDST,
                abbr: abbr,
                abbrDST: abbrDST,
            });
        }
    });

    genericData.sort(sortByAbbrAndName);

    console.log(JSON.stringify(ianaToGeneric, null, 4));
    console.log(JSON.stringify(genericData, null, 4));
}
