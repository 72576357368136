// @ts-nocheck
import * as L from 'leaflet';
import { AWOL_RADIUS, PUMPKIN_ZOOM } from "../map";
import { lineLength } from "../geometry";

// Amount to buffer the clipping rectangle
const CLIPPING_BUFFER = 5;

const TrackRenderer = L.Canvas.extend({
    /**
     * @param layer
     */
    updatePumpkinLine(layer) {
        if (!this._drawing) {
            return;
        }

        if (layer._parts.length === 0) {
            return;
        }

        // Only clip the line when we're zoomed in and drawing circles
        let useClippingPath = false;
        if (layer._map.getZoom() >= PUMPKIN_ZOOM) {
            // If the line length is shorter than the circle, don't draw it
            const length = lineLength(layer._parts[0][0], layer._parts[0][1]);
            if (length < AWOL_RADIUS) {
                return;
            }
            useClippingPath = true;
        }

        this._ctx.save();

        if (useClippingPath) {
            const rectX = Math.min(layer._parts[0][0].x, layer._parts[0][1].x);
            const rectY = Math.min(layer._parts[0][0].y, layer._parts[0][1].y);
            const rectW = Math.max(layer._parts[0][0].x, layer._parts[0][1].x) - rectX;
            const rectH = Math.max(layer._parts[0][0].y, layer._parts[0][1].y) - rectY;

            // Create a clipping path that's the inverse of the middle of the awol circle — stops the dotted line from
            // being rendered through the circle
            this._ctx.beginPath();

            // Buffer the clipping rectangle around the track, so we're not unintentionally clipping any of the track
            this._ctx.rect(rectX - CLIPPING_BUFFER, rectY - CLIPPING_BUFFER, rectW + (CLIPPING_BUFFER * 2), rectH + (CLIPPING_BUFFER * 2));
            const p = this._map.latLngToLayerPoint(layer.midLatLng);
            this._ctx.arc(p.x, p.y, AWOL_RADIUS - 1, 0, Math.PI * 2, true);
            this._ctx.closePath();
            this._ctx.clip();
        }

        // Render the dotted line
        this._ctx.beginPath();
        this._ctx.moveTo(layer._parts[0][0].x, layer._parts[0][0].y);
        this._ctx.lineTo(layer._parts[0][1].x, layer._parts[0][1].y);
        this._fillStroke(this._ctx, layer);

        this._ctx.restore();
    },

    updatePumpkinCircle(layer) {
        // Only render the circle when zoomed in
        if (layer._map.getZoom() >= PUMPKIN_ZOOM) {
            this._updateCircle(layer);
        }
    },
});

L.trackRenderer = function shortcut(options) {
    return new TrackRenderer(options);
};
