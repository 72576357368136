// @ts-nocheck
import { recordDispatch } from "../lib/dispatch-recorder";
import {
    MAP_SET_MOUSE_LAT_LNG,
    SET_SERVER_RANGE_END_TIME,
} from "./actions";
import { SELECT_SEARCHED_VESSEL } from "./slices/search";

const actionsToAvoid = [
    MAP_SET_MOUSE_LAT_LNG,
];

/**
 * Listen in on all redux actions and record them in an external list
 */
const recordDispatchMiddleware = (store) => (next) => (action) => {
    if (actionsToAvoid.includes(action.type)) {
        next(action);
        return;
    }

    let extraInfo;
    switch (action.type) {
        case SET_SERVER_RANGE_END_TIME:
            extraInfo = {
                ...action,
                existingServerAisRangeEndTs: store.getState().timeline.serverAisRangeEndTs,
            };
            break;
        case SELECT_SEARCHED_VESSEL:
            extraInfo = {
                ...action,
            };
            break;
        default:
            extraInfo = {};
    }

    recordDispatch(action.type, extraInfo);
    next(action);
};

export default recordDispatchMiddleware;
