// @ts-nocheck
import debounce from "lodash.debounce";
import { ACTION_TO_USER_PREFERENCE, saveUserPreferences, updateUserPreferencesInState } from "../lib/user-preferences";

const userPrefsDebounceFunctions = {};

/**
 * Create a new debounce function that will debounce updates to user preferences.
 * @param {{}} store
 * @returns {(function(): (*))|*}
 */
function createUserPrefDebounce(store) {
    return debounce((newPrefs) => {
        const state = store.getState();
        saveUserPreferences(state, newPrefs).then();
        updateUserPreferencesInState(state, newPrefs);
    }, 2000); // Only send an update every 2 seconds
}

const userPrefsMiddleware = (store) => (next) => (action) => {
    // Pass the action to the next section, the reducers will run and the state will be updated
    const result = next(action);

    // If action changes a preference that is saved, make API call with relevant fields
    if (action.type in ACTION_TO_USER_PREFERENCE) {
        // Create a debounce function for each action type, this ensures we're not hammering the API with preference
        // updates, while also not losing user preference data
        if (!userPrefsDebounceFunctions[action.type]) {
            userPrefsDebounceFunctions[action.type] = createUserPrefDebounce(store);
        }

        // Get the preference fields that need to be updated from this action
        const newPrefs = ACTION_TO_USER_PREFERENCE[action.type](store.getState());
        userPrefsDebounceFunctions[action.type](newPrefs);
    }
    return result;
};

export default userPrefsMiddleware;
