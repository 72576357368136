// @ts-nocheck
import { VESSEL_GROUP_TYPES } from "./const";
import { emptyObject } from "./utils";

/**
 * Calculates new excluded and included vessel groups based on the previous
 * state and toggled group.
 *
 * How are excludedGroups and includedGroups used to filter vessels?
 * If excludedGroups contains 'ALL' then EXCLUDE everything, except for groups inside includedGroups.
 * If excludedGroups does not contain 'ALL' then INCLUDE everything, except for groups inside excludedGroups.
 */
export default class VesselGroupUpdate {
    constructor(prevState, toggledGroupType, toggledGroupValue) {
        this.prevState = prevState;
        this.toggledGroupType = toggledGroupType;
        this.toggledGroupValue = toggledGroupValue;

        this.excludedGroups = { ...prevState.excludedGroups };
        this.includedGroups = { ...prevState.includedGroups };

        this.calculateIncludedExcluded();
    }

    /**
     * Get newly calculated excluded groups
     * @returns {Object}
     */
    getExcludedGroups() {
        return this.excludedGroups;
    }

    /**
     * Get newly calculates included groups
     * @returns {Object}
     */
    getIncludedGroups() {
        return this.includedGroups;
    }

    /**
     * Calculate new included and excluded groups and set object properties
     */
    calculateIncludedExcluded() {
        const allExcluded = Boolean(this.prevState.excludedGroups[VESSEL_GROUP_TYPES.ALL]);
        const groupWasExcluded = Boolean(this.prevState.excludedGroups[this.toggledGroupType]?.[this.toggledGroupValue]);
        const groupWasIncluded = Boolean(this.prevState.includedGroups[this.toggledGroupType]?.[this.toggledGroupValue]);

        // Special case: toggling ALL groups
        if (this.toggledGroupType === VESSEL_GROUP_TYPES.ALL) {

            // Clear out all included and excluded groups
            this.includedGroups = {};
            this.excludedGroups = {};

            // If excluded groups weren't already ALL then set excluded groups to ALL,
            // otherwise includedGroups and excludedGroups just remain empty (everything turned on)
            if (!this.prevState.excludedGroups[VESSEL_GROUP_TYPES.ALL]) {
                this.excludedGroups = { [VESSEL_GROUP_TYPES.ALL]: true };
            }
            return;
        }

        if (allExcluded) {
            // All groups are excluded by default except for those on the include list

            if (groupWasIncluded) {
                this.removeFromIncluded();
            } else {
                this.addToIncluded();
            }
        } else {
            // All groups are included by default except for those on the exclude list

            if (groupWasExcluded) {
                this.removeFromExcluded();
            } else {
                this.addToExcluded();
            }
        }

        // Check to see if groups can be simplified to just including or excluding everything
        this.checkAllIncluded();
        this.checkAllExcluded();
    }

    /**
     * Add to includedGroups list
     */
    addToIncluded() {
        const includedGroups = { ...this.prevState.includedGroups };
        includedGroups[this.toggledGroupType] = includedGroups[this.toggledGroupType] ?? {};
        includedGroups[this.toggledGroupType][this.toggledGroupValue] = true;

        this.includedGroups = includedGroups;
    }

    /**
     * Remove from includedGroups list
     */
    removeFromIncluded() {
        const includedGroups = { ...this.prevState.includedGroups };
        delete includedGroups[this.toggledGroupType][this.toggledGroupValue];

        if (emptyObject(includedGroups[this.toggledGroupType])) {
            delete includedGroups[this.toggledGroupType];
        }

        this.includedGroups = includedGroups;
    }

    /**
     * Add to excludedGroups list
     */
    addToExcluded() {
        const excludedGroups = { ...this.prevState.excludedGroups };
        excludedGroups[this.toggledGroupType] = excludedGroups[this.toggledGroupType] ?? {};
        excludedGroups[this.toggledGroupType][this.toggledGroupValue] = true;

        this.excludedGroups = excludedGroups;
    }

    /**
     * Remove from excludedGroups list
     */
    removeFromExcluded() {
        const excludedGroups = { ...this.prevState.excludedGroups };
        delete excludedGroups[this.toggledGroupType][this.toggledGroupValue];

        if (emptyObject(excludedGroups[this.toggledGroupType])) {
            delete excludedGroups[this.toggledGroupType];
        }

        this.excludedGroups = excludedGroups;
    }

    /**
     * Get all top-level groups for given filter summary
     * @returns {Object[]} all top level groups based on whether groupBy is FLAG_STATE or VESSEL_TYPE
     */
    allTopLevelGroups() {
        return this.prevState.filterSetSummary.groupsByType.find((g) => (g.groupType === this.prevState.groupBy)).groups;
    }

    /**
     * Returns true if all groups have individually been toggled off
     * @returns {Boolean}
     */
    allGroupsExcluded() {
        return this.excludedGroups[this.toggledGroupType] && Object.keys(this.excludedGroups[this.toggledGroupType]).length === this.allTopLevelGroups().length;
    }

    /**
     * Returns true if all groups have individually been toggled on
     * @returns {Boolean}
     */
    allGroupsIncluded() {
        return this.includedGroups[this.toggledGroupType] && Object.keys(this.includedGroups[this.toggledGroupType]).length === this.allTopLevelGroups().length;
    }

    /**
     * Checks to see if all groups have individually been toggled off.
     * Changes to a simpler ALL groups off state if they have.
     */
    checkAllExcluded() {
        if (this.allGroupsExcluded()) {
            this.excludedGroups = { [VESSEL_GROUP_TYPES.ALL]: true };
            this.includedGroups = {};
        }
    }

    /**
     * Checks to see if all groups have individually been toggled on.
     * Clears the list of excluded and included groups if they have.
     */
    checkAllIncluded() {
        if (this.allGroupsIncluded()) {
            this.excludedGroups = {};
            this.includedGroups = {};
        }
    }
}
