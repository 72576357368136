// @ts-nocheck
import * as L from "leaflet";

const NukeLayerGroup = L.FeatureGroup.extend({

    /**
     * Whether this group has layers
     * @returns {boolean}
     */
    hasLayers() {
        return Object.keys(this._layers).length > 0;
    },

    /**
     * Removes a layer from the layer group and any other event parents
     * @param layer
     */
    nukeLayer(layer) {
        this.removeLayer(layer);

        // Also remove this layer from any other event parents, in our case that's a vessel group
        // TODO: Modify the layer so we're storing all parents regardless of whether they have events or not
        Object.keys(layer._eventParents).forEach((key) => {
            delete layer._eventParents[key]._layers[layer._leaflet_id];
        });
    },

    /**
     * Nukes all layers in this group
     */
    nukeAllLayers() {
        Object.keys(this._layers).forEach((key) => {
            this.nukeLayer(this._layers[key]);
        });
    },

    /**
     * Adds a layer to this group but doesn't add it to the map, this is used when we want the layer data
     * but don't immediately want it drawn on the map, i.e. h3 tracks when a vessel is selected
     * and already has a selected track drawn.
     * @param layer
     * @return {NukeLayerGroup}
     */
    addLayerNoMap(layer) {
        layer.addEventParent(this);

        const id = this.getLayerId(layer);
        this._layers[id] = layer;
        return this;
    },

});

L.nukeLayerGroup = function shortcut(layers, options) {
    return new NukeLayerGroup(layers, options);
};
L.NukeLayerGroup = NukeLayerGroup;
