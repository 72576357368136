// @ts-nocheck
import ActivityBase from "./ActivityBase";

export default class ActivitySingleVesselTagging extends ActivityBase {
    static name = "ActivitySingleVesselTagging";

    /**
     * @returns {Object}
     */
    get data() {
        return this._params.vesselTagging.singleResult;
    }

    /**
     * @returns {Object}
     */
    get vesselTagging() {
        return this.data.vesselTagging;
    }

    /**
     * @returns {Object}
     */
    get vesselProfile() {
        return this.data.vesselProfile;
    }

    /**
     * @returns {Object}
     */
    get tag() {
        return this.data.tag;
    }

    /**
     * @returns {string}
     */
    get userName() {
        return this.data.userName;
    }

    /**
     * @returns {string}
     */
    get activityAction() {
        return this.data.activityAction;
    }

    /**
     * @returns {Object|null}
     */
    get note() {
        return this.vesselTagging.notes.find((note) => note.createdAt === this.ts);
    }

    /**
     * @returns {string}
     */
    get uuid() {
        return `ActivitySingleVesselTagging-${this.ts}-${this.vesselTagging.vesselTaggingId}-${this.activityAction}`;
    }
}
