// @ts-nocheck
import { createSelector } from "reselect";
import VesselFilterEnteredAoi from "./VesselFilterEnteredAoi";
import VesselFilterInAoi from "./VesselFilterInAoi";

export default class VesselFilterArea {

    static fromState = createSelector(
        [
            VesselFilterInAoi.fromState,
            VesselFilterEnteredAoi.fromState,
        ],
        (...filters) => {
            // Return first area filter that isn't null
            return filters.find((filter) => filter);
        },
    );
}
