// @ts-nocheck
// Disable, functions are in line with Leaflet functions
/* eslint-disable func-names */
import * as L from "leaflet";
import "./BasicLayer";

export const ARROW_WIDTH = 5;
export const ARROW_HEIGHT = 8;

let offscreenArrowHeadImg;

function getOffscreenArrowHeadImg() {
    return offscreenArrowHeadImg;
}

/**
 * Initialise of the offscreen arrowhead svg image to be used in the renderer for drawing arrow heads
 */
function initOffscreenArrowHeadImg() {
    if (offscreenArrowHeadImg) {
        return;
    }

    offscreenArrowHeadImg = document.createElement("img");
    offscreenArrowHeadImg.src = "/svg/line-arrowhead.svg";
}

initOffscreenArrowHeadImg();

const MeasurementLineArrowHead = L.BasicLayer.extend({
    offscreenArrowHeadImg: null,
    bearing: null,

    initialize: function (latLng, bearing, renderer, options) {
        L.BasicLayer.prototype.initialize.call(this, latLng, renderer, options);
        this.bearing = bearing;
        this.offscreenArrowHeadImg = getOffscreenArrowHeadImg();
    },

    /**
     * Update this layer and redraw
     * @param {LatLng} latLng
     * @param {Number} bearing
     */
    update: function (latLng, bearing) {
        const changed = this.changed(latLng, bearing);

        if (changed) {
            this._latlng = latLng;
            this.bearing = bearing;
            this.redraw();
        }
    },

    /**
     * Have the properties for this layer changed?
     * @param {LatLng} latLng
     * @param {Number} bearing
     * @returns {Boolean} true if properties have changed
     */
    changed: function (latLng, bearing) {
        return latLng.lat !== this._latlng.lat || latLng.lng !== this._latlng.lng || bearing !== this.bearing;
    },

    /**
     * Update pixel bounds for this layer
     */
    updateBounds: function () {
        const t = this._clickTolerance();
        const p = [ARROW_WIDTH / 2 + t, ARROW_HEIGHT / 2 + t];
        this._pxBounds = L.bounds(this._point.subtract(p), this._point.add(p));
    },

    /**
     * Render the arrow head with current properties
     */
    updatePath: function () {
        this._renderer.updateArrowHead(this);
    },
});

// Add a shortcut to the global Leaflet object
L.measurementLineArrowHead = function shortcut(latLng, bearing, renderer, options) {
    return new MeasurementLineArrowHead(latLng, bearing, renderer, options);
};

L.MeasurementLineArrowHead = MeasurementLineArrowHead;
