// @ts-nocheck
import { OPERATORS, SPEED_IN_AREA_MAX, SPEED_IN_AREA_MIN, UNITS_NM } from "../const";
import { getSpeedUnit } from "../geometry";
import { convertFromKnots, convertToKnots } from "../measurement";
import { round } from "../utils";

const DEFAULT_FILTER_SET_SPEED_OPTIONS = {
    minSpeed: SPEED_IN_AREA_MIN,
    maxSpeed: SPEED_IN_AREA_MAX,
    includePortAreas: false,
};

/**
 * Class to adapt speed options between different formats.
 */
export default class SpeedOptionsAdapter {
    /**
     * Adapt speed in area options from filterSet format to form ui format.
     * @param {SpeedOptionsFilterSet} filterSetSpeedOptions
     * @param {string} distanceUnit
     * @returns {SpeedOptionsForm}
     */
    static filterSetToForm(filterSetSpeedOptions, distanceUnit = UNITS_NM) {
        if (!filterSetSpeedOptions) {
            filterSetSpeedOptions = DEFAULT_FILTER_SET_SPEED_OPTIONS;
        }

        const op = SpeedOptionsAdapter.opFromFilterSet(filterSetSpeedOptions);
        let value = '';
        let value2 = '';

        const minSpeed = filterSetSpeedOptions.minSpeed > SPEED_IN_AREA_MIN
            ? round(convertFromKnots(filterSetSpeedOptions.minSpeed, getSpeedUnit(distanceUnit)), 4)
            : null;

        const maxSpeed = filterSetSpeedOptions.maxSpeed < SPEED_IN_AREA_MAX
            ? round(convertFromKnots(filterSetSpeedOptions.maxSpeed, getSpeedUnit(distanceUnit)), 4)
            : null;

        switch (op) {
            case OPERATORS.LESS_THAN:
                value = maxSpeed;
                break;
            case OPERATORS.GREATER_THAN:
                value = minSpeed;
                break;
            case OPERATORS.BETWEEN:
                value = minSpeed;
                value2 = maxSpeed;
                break;
            default:
        }

        return {
            op: op,
            value: value,
            value2: value2,
            includePortAreas: filterSetSpeedOptions.includePortAreas,
        };
    }

    /**
     * Adapt speed in area options from form ui format to filterSet format.
     * @param {VesselSpeedForm} form
     * @param {string} distanceUnit
     * @returns {SpeedOptionsFilterSet}
     */
    static formToFilterSet(form, distanceUnit = UNITS_NM) {
        const valueInKnots = form.getSpeedValue()
            ? convertToKnots(Number(form.getSpeedValue()), getSpeedUnit(distanceUnit))
            : null;

        const value2InKnots = form.getSpeedValue2()
            ? convertToKnots(Number(form.getSpeedValue2()), getSpeedUnit(distanceUnit))
            : null;


        let minSpeed = SPEED_IN_AREA_MIN;
        let maxSpeed = SPEED_IN_AREA_MAX;

        switch (form.getSpeedOp()) {
            case OPERATORS.LESS_THAN:
                maxSpeed = valueInKnots;
                break;
            case OPERATORS.GREATER_THAN:
                minSpeed = valueInKnots;
                break;
            case OPERATORS.BETWEEN:
                minSpeed = valueInKnots;
                maxSpeed = value2InKnots;
                break;
            default:
        }

        return {
            minSpeed: minSpeed,
            maxSpeed: maxSpeed,
            includePortAreas: form.getIncludePortAreas(),
        };
    }

    /**
     * Adapt legacy URL formatted speed options into the filterSet format.
     * @param {{ op: string, value: string, value2: string, includePortAreas: boolean }} legacyUrlSpeedOptions
     * @returns {SpeedOptionsFilterSet}
     */
    static legacyUrlToFilterSet(legacyUrlSpeedOptions) {
        let minSpeed = SPEED_IN_AREA_MIN;
        let maxSpeed = SPEED_IN_AREA_MAX;

        switch (legacyUrlSpeedOptions.op) {
            case OPERATORS.LESS_THAN:
                maxSpeed = Number(legacyUrlSpeedOptions.value);
                break;
            case OPERATORS.GREATER_THAN:
                minSpeed = Number(legacyUrlSpeedOptions.value);
                break;
            case OPERATORS.BETWEEN:
                minSpeed = Number(legacyUrlSpeedOptions.value);
                maxSpeed = Number(legacyUrlSpeedOptions.value2);
                break;
            default:
        }

        return {
            minSpeed: minSpeed,
            maxSpeed: maxSpeed,
            includePortAreas: legacyUrlSpeedOptions.includePortAreas,
        };
    }

    /**
     * Get the operator to display for speed options formatted for a filter set.
     * @param {SpeedOptionsFilterSet} speedOptions
     * @returns {string}
     */
    static opFromFilterSet(speedOptions) {
        if (speedOptions.minSpeed > SPEED_IN_AREA_MIN && speedOptions.maxSpeed < SPEED_IN_AREA_MAX) {
            return OPERATORS.BETWEEN;
        }

        if (speedOptions.maxSpeed < SPEED_IN_AREA_MAX) {
            return OPERATORS.LESS_THAN;
        }

        return OPERATORS.GREATER_THAN;
    }
}
