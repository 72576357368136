// @ts-nocheck
import AlertRule from "../AlertRule";
import ActivityBase from "./ActivityBase";

export default class ActivityAlertRule extends ActivityBase {
    static name = "ActivityAlertRule";

    /**
     * @returns {Object}
     */
    get data() {
        return this._params.alertRule;
    }

    /**
     * @returns {AlertRule}
     */
    get alertRule() {
        return AlertRule.deserialize(this.data.alertRule);
    }

    /**
     * @returns {string}
     */
    get activityAction() {
        return this.data.activityAction;
    }

    /**
     * @returns {string}
     */
    get uuid() {
        return `ActivityAlertRule-${this.ts}-${this.alertRule.getId()}-${this.activityAction}`;
    }
}
