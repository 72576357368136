// @ts-nocheck
import { isVesselRegistered } from "../analysis";
import { FISHING_REGISTRATION_TYPES, VESSEL_GROUP_TYPES } from "../const";
import { emptyObject } from "../utils";
import { normaliseVesselTypeId, vesselIsFishing } from "../vessel-utils";

/**
 * Return true if vessel is matched with any of the provided groups
 * @param {Object} vessel
 * @param {Object} groups
 * @returns {Boolean}
 */
function vesselInGroups(vessel, groups) {
    // Vessel type
    if (groups[VESSEL_GROUP_TYPES.VESSEL_TYPE] &&
        groups[VESSEL_GROUP_TYPES.VESSEL_TYPE][normaliseVesselTypeId(vessel.vesselTypeId)]
    ) {
        return true;
    }

    // Flag state
    if (groups[VESSEL_GROUP_TYPES.FLAG_STATE] &&
        groups[VESSEL_GROUP_TYPES.FLAG_STATE][vessel.flagState.code]
    ) {
        return true;
    }

    // Hitchhiker pest
    if (groups[VESSEL_GROUP_TYPES.HITCHHIKER] &&
        groups[VESSEL_GROUP_TYPES.HITCHHIKER][vessel.endOfRangeRiskLevel]
    ) {
        return true;
    }

    // Fishing registration
    if (groups[VESSEL_GROUP_TYPES.FISHING_REGISTERS]) {

        // Registered fishing vessel
        if (groups[VESSEL_GROUP_TYPES.FISHING_REGISTERS][FISHING_REGISTRATION_TYPES.REGISTERED] &&
            isVesselRegistered(vessel)
        ) {
            return true;
        }

        // Unregistered fishing vessel
        if (groups[VESSEL_GROUP_TYPES.FISHING_REGISTERS][FISHING_REGISTRATION_TYPES.UNREGISTERED] &&
            vesselIsFishing(vessel) && !isVesselRegistered(vessel)
        ) {
            return true;
        }

        // Non-fishing vessel
        if (groups[VESSEL_GROUP_TYPES.FISHING_REGISTERS][FISHING_REGISTRATION_TYPES.NON_FISHING] &&
            !vesselIsFishing(vessel) && !isVesselRegistered(vessel)
        ) {
            return true;
        }
    }

    return false;
}

/**
 * Return true if vessel should be shown on map.
 * If ALL are excluded then vessel must be in includedGroups
 * otherwise vessel must NOT be in excludedGroups
 * @param {Object} vessel
 * @param {Object} excludedGroups
 * @param {Object} includedGroups
 * @returns {Boolean}
 */
function includeVessel(vessel, excludedGroups, includedGroups) {
    if (excludedGroups[VESSEL_GROUP_TYPES.ALL]) {
        return (vesselInGroups(vessel, includedGroups));
    }

    if (!emptyObject(excludedGroups)) {
        return !vesselInGroups(vessel, excludedGroups);
    }

    return true;
}

/**
 * Filter vessels array based on excluded and included groups.
 *
 * The logic is:
 * If excludedGroups contains 'ALL' then EXCLUDE everything, except for groups inside includedGroups.
 * If excludedGroups does not contain 'ALL' then INCLUDE everything, except for groups inside excludedGroups.
 *
 * @param {Object[]} vessels
 * @param {Object} excludedGroups
 * @param {Object} includedGroups
 * @returns {Object[]} filtered vessels
 */
export default function filterVesselsByGroup(vessels, excludedGroups, includedGroups) {
    if (excludedGroups[VESSEL_GROUP_TYPES.ALL] && emptyObject(includedGroups)) {
        // All are hidden
        return [];
    }

    return vessels.filter((vessel) => {
        return includeVessel(vessel, excludedGroups, includedGroups);
    });
}
