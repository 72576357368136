// @ts-nocheck
const urlParams = new URLSearchParams(window.location.search.slice(1));

// Get a parameter from the url
// IMPORTANT: Only reflects initial page load params
export function urlGet(key) {
    let value;

    // Sometimes a URL value will become corrupted, in that case catch the JSON exception and delete
    // the url parameter
    try {
        value = JSON.parse(urlParams.get(key));
    } catch (e) {
        urlParams.delete(key);
        syncUrl();
        return null;
    }
    return value;
}

function syncUrl() {
    const queryString = urlParams.toString();
    window.history.replaceState({}, "", queryString.length > 0 ? `?${queryString}` : window.location.pathname);
}

/**
 * Whether or not passed string contains new lines
 * @param {string} string
 * @return {boolean}
 */
export function containsNewLine(string) {
    return /[\n\r]/.test(string);
}

/**
 * Gets a url from string from a location search string
 * @param {string} locationSearch
 * @return {string|null}
 */
export function getUrlFrom(locationSearch) {
    const searchParams = new URLSearchParams(locationSearch);
    const urlFrom = searchParams.get('r');

    // Check if there's a from parameter in the url and do some basic validation.
    // Pre-pending the current origin (e.g. https://starboard.nz) prevents any malicious url injection here
    if (urlFrom && urlFrom.charAt(0) === "/" && !containsNewLine(urlFrom)) {
        return `${window.location.origin}${urlFrom}`;
    }
    return null;
}

/**
 * Gets a previous path from the 'from' url parameter, or from the history state.
 * @return {string}
 */
export function getPreviousPath() {
    const locationSearch = window.location.search.slice(1);
    const urlFrom = getUrlFrom(locationSearch);
    if (urlFrom) {
        return urlFrom;
    }
    return "/";
}
