// @ts-nocheck
import { canEditTaggings } from "./tag";

/**
 * Return a unique key for a draft vessel tagging note
 * @param {number} vesselTaggingId
 * @param {number|null} noteId
 * @returns {string}
 */
export function getDraftVesselTaggingNoteKey(vesselTaggingId, noteId) {
    return `${vesselTaggingId}-${noteId || "new"}`;
}

/**
 * Returns true if the given user can edit the given note
 * @param {Object} note
 * @param {number} currentUserId
 * @returns {boolean}
 */
export function canEditNote(note, currentUserId) {
    return note.createdBy === currentUserId;
}

/**
 * Returns true if the current user can add a note to a tagging by the given tag
 * @param {Object} tag
 * @returns {boolean}
 */
export function canAddNoteToTagging(tag) {
    return canEditTaggings(tag);
}
