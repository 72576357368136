// @ts-nocheck
import intersection from "lodash.intersection";
import { arrayUnique } from "./utils";

export default class Pool {

    constructor({ debug = false } = {}) {
        /** @type {*[]} */
        this.items = [];

        /** @type {boolean} */
        this.debug = debug;

        /** @type {number} */
        this.total = 0;
    }

    /**
     * Resets the items in the pool
     */
    reset() {
        this.items = [];
        this.total = 0;
    }

    /**
     * The number of items in the pool
     * @returns {number}
     */
    count() {
        return this.items.length;
    }

    /**
     * Get the total number of items after the last queue call
     * @returns {number}
     */
    getTotal() {
        return this.total;
    }

    /**
     * Pops count number of items from the pool
     * @param {number} count
     * @returns {*[]}
     */
    pop(count) {
        return this.items.splice(-count, count);
    }

    /**
     * Gets all items in the pool
     * @returns {*[]}
     */
    get() {
        return this.items;
    }

    /**
     * @param {*[]} items
     */
    queue(items) {
        this.items = arrayUnique([...this.items, ...items]);
        this.sort();

        // Whenever we queue new items to the pool, record the maximum number of items before items are popped off.
        // Used to calculate loading percentage
        this.total = this.count();
    }

    /**
     * Sort items in the pool
     */
    sort() {
        this.items.sort();
    }

    /**
     * Removes all items in the pool that aren't in the passed array of items
     * @param {*[]} items
     */
    intersect(items) {
        this.items = intersection(items, this.items);
    }

    /**
     * Filters current items based on the filterCb
     * @param {function} filterCb
     */
    filter(filterCb) {
        this.items = this.items.filter((item) => filterCb(item));
    }
}
