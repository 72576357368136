// @ts-nocheck
import ActivityBase from "./ActivityBase";

export default class ActivityMultiVesselTagging extends ActivityBase {
    static name = "ActivityMultiVesselTagging";

    /**
     * @returns {Object}
     */
    get data() {
        return this._params.vesselTagging.multipleResults;
    }

    /**
     * @returns {Object[]}
     */
    get vesselProfiles() {
        return this.data.vesselProfiles;
    }

    /**
     * @returns {Object}
     */
    get tag() {
        return this.data.tag;
    }

    /**
     * @returns {string}
     */
    get userName() {
        return this.data.userName;
    }

    /**
     * @returns {string}
     */
    get activityAction() {
        return this.data.activityAction;
    }

    /**
     * @returns {Object}
     */
    get note() {
        return this.data.note;
    }

    /**
     * @returns {string}
     */
    get uuid() {
        return `ActivityMultiVesselTagging-${this.ts}-${this.tag.tagId}-${this.activityAction}`;
    }
}
