// @ts-nocheck
import { PAGE_ALERTS, PAGE_MAP, PAGE_TAGGED_VESSELS, SECTION_MANAGE_ORGS, SECTION_SETTINGS, PAGE_VESSEL_REPORT, PAGE_ENTERPRISE } from "./const";

const pages = {
    [PAGE_MAP]: /^\/?$/,
    [PAGE_TAGGED_VESSELS]: /^\/tagged-vessels\/?$/,
    [PAGE_ALERTS]: /^\/settings\/alerts\/?$/,
    [PAGE_VESSEL_REPORT]: /^\/vessels\//,
    [PAGE_ENTERPRISE]: /^\/enterprise\//,
};

const sections = {
    [SECTION_SETTINGS]: /^\settings\//,
    [SECTION_MANAGE_ORGS]: /^\/manage-organisations\//,
};

function isOnPage(page, pathname) {
    return Boolean(pathname.match(pages[page]));
}

/**
 * Returns true if given path matches a page inside the given section
 * @param {string} section
 * @param {string} pathname
 * @returns {boolean}
 */
function isInSection(section, pathname) {
    pathname = pathname.endsWith("/") ? pathname : `${pathname}/`;
    return Boolean(pathname.match(sections[section]));
}

/**
 * Returns true if given path matches the map page
 * @param {string} pathname
 * @returns {boolean}
 */
export function isOnMap(pathname) {
    return isOnPage(PAGE_MAP, pathname);
}

/**
 * Returns true if given path matches the vessel report page
 * @param {string} pathname
 * @returns {boolean}
 */
export function isOnVesselReport(pathname) {
    return isOnPage(PAGE_VESSEL_REPORT, pathname);
}

/**
 * Returns true if given path matches the tagged vessels page
 * @param {string} pathname
 * @returns {boolean}
 */
export function isOnTaggedVessels(pathname) {
    return isOnPage(PAGE_TAGGED_VESSELS, pathname);
}

/**
 * Returns true if given path matches the alerts page
 * @param {string} pathname
 * @returns {boolean}
 */
export function isOnAlerts(pathname) {
    return isOnPage(PAGE_ALERTS, pathname);
}

/**
 * Returns true if given path matches a page inside the manage orgs section
 * @param {string} pathname
 * @returns {boolean}
 */
export function inManageOrgsSection(pathname) {
    return isInSection(SECTION_MANAGE_ORGS, pathname);
}

/**
 * Returns true if given path matches the enterprise report page
 * @param {string} pathname
 * @returns {boolean}
 */
export function isOnEnterpriseReport(pathname) {
    return isOnPage(PAGE_ENTERPRISE, pathname);
}
