// @ts-nocheck
import { binarySearch, utcTs } from "./utils";
import CacheMem from "./CacheMem";

/**
 * Optimised version of CacheMem using binarySearch when the keys are sortable
 */
export default class CacheMemSorted extends CacheMem {

    /**
     * Return the index of the stored item or undefined
     * @param {*} key
     * @return {Promise<number>}
     */
    async getIndex(key) {
        const i = binarySearch(key, this.cache, {
            valueFn: (o) => o[this.key],
            returnIndex: true,
        });

        // Ensure return value is consistent with other caches
        return i !== undefined ? i : -1;
    }

    /**
     * Return the stored item with passed key or undefined if item doesn't exist
     * @param {*} key
     * @return {Promise<*>}
     */
    async get(key) {
        return binarySearch(key, this.cache, {
            valueFn: (o) => o[this.key],
        });
    }

    /**
     * Returns all stored items with passed keys, items that don't exist aren't included
     * @param {*[]} keys
     * @return {Promise<*[]>}
     */
    async getMultiple(keys) {
        const getPromises = keys.map((key) => this.get(key));

        // Wait for all gets to complete and filter out undefined results before returning
        return (await Promise.all(getPromises)).filter((v) => v !== undefined);
    }

    /**
     * Add a new item to the cache, if an item with the same key already exists it's overwritten by the newItem
     * @param {*} newItem
     * @return {Promise<void>}
     */
    async set(newItem) {
        // If createdIndex is set and the item doesn't already have a _created field, at a UTC timestamp
        if (this.createdIndex && !newItem._created) {
            newItem._created = utcTs();
        }

        // Search for an existing item by key
        const result = binarySearch(newItem[this.key], this.cache, {
            valueFn: (o) => o[this.key],
            returnIndex: true,
            startEnd: true,
        });

        if (result.index !== undefined) {
            // Item exists, replace with new item
            this.cache[result.index] = newItem;
        } else {
            // Item not found, splice into array in correct order
            this.cache.splice(result.start, 0, newItem);
        }
    }
}
