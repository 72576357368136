// @ts-nocheck
import { createSelector } from "reselect";
import { getFilterAuthorisations } from "../../redux/derived-data";
import { FILTER_SET_NAMES, OPERATORS, REGISTER_NAMES } from "../const";
import { emptyObject } from "../utils";
import VesselFilterBase from "./VesselFilterBase";

export default class VesselFilterWCPFCAttributes extends VesselFilterBase {
    name = FILTER_SET_NAMES.FILTER_BY_WCPFC_ATTRIBUTE;

    /**
     * fromState is a static factory method for generating and caching VesselFilterWCPFCAttributes objects
     * based on the current state of the Redux store.
     * @param {Object} state
     * @returns {VesselFilterWCPFCAttributes[]|null}
     * @example
     * const filter = VesselFilterWCPFCAttributes.fromState(state);
     */
    static fromState = createSelector(
        [getFilterAuthorisations],
        (authorisationsFilter) => {
            if (emptyObject(authorisationsFilter) || !authorisationsFilter.subfilters) {
                return null;
            }

            const filters = [];

            if (authorisationsFilter.subfilters[REGISTER_NAMES.WCPFC][OPERATORS.IS]) {
                authorisationsFilter.subfilters[REGISTER_NAMES.WCPFC][OPERATORS.IS].forEach((wcpfcAttribute) => {
                    filters.push(new VesselFilterWCPFCAttributes([wcpfcAttribute]));
                });
            }

            if (authorisationsFilter.subfilters[REGISTER_NAMES.WCPFC][OPERATORS.IS_NOT]) {
                authorisationsFilter.subfilters[REGISTER_NAMES.WCPFC][OPERATORS.IS_NOT].forEach((wcpfcAttribute) => {
                    filters.push(new VesselFilterWCPFCAttributes([wcpfcAttribute], true));
                });
            }

            return filters;
        },
    );

    /**
     * @param {string[]} wcpfcAttributes
     * @param {boolean} exclude
     */
    constructor(wcpfcAttributes, exclude = false) {
        super(exclude);
        this.wcpfcAttributes = wcpfcAttributes;
    }

    /**
     * @returns {{name: string, exclude: boolean, wcpfcAttributes: string[] }}
     */
    getFilter() {
        return {
            name: this.name,
            exclude: this.exclude,
            wcpfcAttributes: this.wcpfcAttributes,
        };
    }

    /**
     * @param {Object} data
     * @returns {VesselFilterWCPFCAttributes}
     */
    static deserialize(data) {
        return new VesselFilterWCPFCAttributes(data.wcpfcAttributes, Boolean(data.exclude));
    }
}
