// @ts-nocheck
import * as L from "leaflet";

L.DebugGrid = L.GridLayer.extend({
    // eslint-disable-next-line func-names
    createTile: function (coords) {
        const tile = document.createElement('div');
        tile.className = "debug-grid";
        tile.innerHTML = `
            <div>z <span class='text--bold'>${coords.z}<span></div>
            <div>x <span class='text--bold'>${coords.x}</span></div>
            <div>y <span class='text--bold'>${coords.y}</span></div>
            <div>y (tms) <span class='text--bold'>${2 ** coords.z - coords.y - 1}</span></div>
        `;

        return tile;
    },
});

L.debugGrid = (opts) => {
    return new L.DebugGrid(opts);
};
