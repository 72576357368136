// @ts-nocheck
import intersection from "lodash.intersection";
import { arrayUnique } from "../../lib/utils";

const MAP_ADD_LOADING_H3_IDS = 'MAP_ADD_LOADING_H3_IDS';
const MAP_SET_LOADING_H3_IDS = 'MAP_SET_LOADING_H3_IDS';
const MAP_INTERSECT_LOADING_H3_IDS = 'MAP_INTERSECT_LOADING_H3_IDS';

export const initialState = [];

export function addLoadingH3Ids(ids) {
    return {
        type: MAP_ADD_LOADING_H3_IDS,
        ids: ids,
    };
}

export function setLoadingH3Ids(ids) {
    return {
        type: MAP_SET_LOADING_H3_IDS,
        ids: ids,
    };
}

export function intersectLoadingH3Ids(ids) {
    return {
        type: MAP_INTERSECT_LOADING_H3_IDS,
        ids: ids,
    };
}

export function mapLoadingH3IdsReducer(state = initialState, action) {
    switch (action.type) {
        case MAP_ADD_LOADING_H3_IDS: {
            return arrayUnique(state.concat(action.ids));
        }
        case MAP_SET_LOADING_H3_IDS: {
            return action.ids;
        }
        case MAP_INTERSECT_LOADING_H3_IDS: {
            // Remove any loading cells that aren't in the list of ids
            return intersection(state, action.ids);
        }
        default:
            return state;
    }
}
