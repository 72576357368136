// @ts-nocheck
import { createSelector } from "reselect";
import { getAoiUuid, getCurrentOrganisationId, getFilterActivity, getFilterNoteworthyActivity, getSelectedDataEndTs, getSelectedDataStartTs } from "../../redux/derived-data";
import { EVENT_ECTR, EVENT_ECTS, EVENT_IETS, EVENT_SPED, FILTER_SET_NAMES, OPERATORS } from "../const";
import { arrayUnique, emptyObject } from "../utils";
import VesselFilterTimeBound from "./VesselFilterTimeBound";

export default class VesselFilterActivity extends VesselFilterTimeBound {
    name = FILTER_SET_NAMES.FILTER_BY_ACTIVITY;

    /**
     * fromState is a static factory method for generating and caching VesselFilterActivity objects
     * based on the current state of the Redux store.
     * @param {Object} state
     * @returns {VesselFilterActivity[]|null}
     * @example
     * const filter = VesselFilterActivity.fromState(state);
     */
    static fromState = createSelector(
        [
            getSelectedDataStartTs,
            getSelectedDataEndTs,
            getFilterActivity,
            getFilterNoteworthyActivity,
            getCurrentOrganisationId,
            getAoiUuid,
        ],
        (startTs, endTs, activityFilter, noteworthyActivityFilter, orgId, aoiUuid) => {
            if (emptyObject(activityFilter) && emptyObject(noteworthyActivityFilter)) {
                return null;
            }

            let speedOptions = null;

            if (activityFilter.activities.includes(EVENT_SPED)) {
                speedOptions = {
                    ...activityFilter.speedOptions,
                    uuid: aoiUuid,
                };
            }

            const filters = [];

            let activities = activityFilter.activities;

            if (activityFilter.op === OPERATORS.AND) {
                // All of
                activities.forEach((activity) => {
                    let _activities = [activity];
                    if (activity === EVENT_ECTR) {
                        _activities = arrayUnique(activityFilter.activities.concat([EVENT_ECTR, EVENT_IETS, EVENT_ECTS]));
                    }
                    filters.push(new VesselFilterActivity(_activities, orgId, speedOptions, startTs, endTs, null, false));
                });

            } else if (activityFilter.op === OPERATORS.NOT_IN) {
                // Add encounter subtypes if filtering by ECTR
                if (activityFilter.activities.includes(EVENT_ECTR)) {
                    activities = arrayUnique(activityFilter.activities.concat([EVENT_IETS, EVENT_ECTS]));
                }

                // None of
                filters.push(new VesselFilterActivity(activities, orgId, speedOptions, startTs, endTs, null, true));

            } else {
                // Add encounter subtypes if filtering by ECTR
                if (activityFilter.activities.includes(EVENT_ECTR)) {
                    activities = arrayUnique(activityFilter.activities.concat([EVENT_IETS, EVENT_ECTS]));
                }

                // Any of
                filters.push(new VesselFilterActivity(activities, orgId, speedOptions, startTs, endTs, null, false));
            }

            // Check for extra 'noteworthy' activity filter
            if (!emptyObject(noteworthyActivityFilter)) {
                let noteworthySpeedOptions = null;

                if (noteworthyActivityFilter.noteworthyActivities.includes(EVENT_SPED)) {
                    noteworthySpeedOptions = {
                        ...noteworthyActivityFilter.speedOptions,
                        uuid: aoiUuid,
                    };
                }

                noteworthyActivityFilter.noteworthyActivities.forEach((activity) => {
                    filters.push(new VesselFilterActivity([activity], orgId, noteworthySpeedOptions, startTs, endTs));
                });
            }

            return filters;
        },
    );

    /**
     * @param {string[]} eventTypes
     * @param {number} orgId
     * @param {Object} speedOptions
     * @param {number} startTs
     * @param {number} endTs
     * @param {number} duration
     * @param {boolean} exclude
     */
    constructor(eventTypes, orgId, speedOptions, startTs, endTs, duration = null, exclude = false) {
        super(startTs, endTs, duration, exclude);
        this.eventTypes = eventTypes;
        this.orgId = orgId;
        this.speedOptions = speedOptions;
    }

    /**
     * @returns {{name: string, startTime: number, endTime: number, eventTypes, speedOptions}}
     */
    getFilter() {
        return {
            name: this.name,
            startTime: this.startTs,
            endTime: this.endTs,
            duration: this.duration,
            exclude: this.exclude,
            eventTypes: this.eventTypes,
            orgID: this.orgId,
            speedOptions: this.speedOptions,
        };
    }

    /**
     * @param {Object} data
     * @returns {VesselFilterActivity}
     */
    static deserialize(data) {
        return new VesselFilterActivity(data.eventTypes, data.orgID, data.speedOptions, data.startTime, data.endTime, data.duration, Boolean(data.exclude));
    }
}
