// @ts-nocheck
import intersection from "lodash.intersection";
import { utcTs } from "./utils";

export default class CacheMem {

    constructor(name, key, { createdIndex, ttl, keyStartTsPos } = {}) {
        this.name = name;
        this.key = key;
        this.cache = [];
        this.createdIndex = Boolean(createdIndex);
        this.ttl = ttl;
        this.keyStartTsPos = keyStartTsPos;
    }

    /**
     * Return the index of the stored item or undefined
     * @param {*} key
     * @return {Promise<number>}
     */
    async getIndex(key) {
        return this.cache.findIndex((item) => item[this.key] === key);
    }

    /**
     * Return the stored item with passed key or undefined if item doesn't exist
     * @param {*} key
     * @return {Promise<*>}
     */
    async get(key) {
        return this.cache.find((item) => item[this.key] === key);
    }

    /**
     * Returns all stored items with passed keys, items that don't exist aren't included
     * @param {*[]} keys
     * @return {Promise<*[]>}
     */
    async getMultiple(keys) {
        return this.cache.filter((item) => keys.includes(item[this.key]));
    }

    /**
     * Add a new item to the cache, if an item with the same key already exists it's overwritten by the newItem
     * @param {*} newItem
     * @return {Promise<void>}
     */
    async set(newItem) {
        if (this.createdIndex && !newItem._created) {
            newItem._created = utcTs();
        }
        const index = this.cache.findIndex((item) => item[this.key] === newItem[this.key]);
        if (index !== -1) {
            this.cache[index] = newItem;
        } else {
            this.cache.push(newItem);
        }
    }

    /**
     * Add multiple items to the cache
     * @param {*[]} items
     * @return {Promise<void>}
     */
    async setMultiple(items) {
        items.forEach((item) => {
            this.set(item);
        });
    }

    /**
     * Returns all item keys
     * @return {Promise<*[]>}
     */
    async allKeys() {
        return this.cache.map((v) => v[this.key]);
    }

    /**
     * Returns passed keys are that present in the cache
     * @param {*[]} keys
     * @return {Promise<*>}
     */
    async checkMultipleExist(keys) {
        return intersection(keys, await this.allKeys());
    }

    /**
     * Clears the cache
     * @return {Promise<void>}
     */
    async clear() {
        this.cache = [];
    }

    /**
     * Delete all items with passed keys
     * @param {*[]} keys
     * @returns {Promise<null>}
     */
    async deleteMultiple(keys) {
        if (keys.length === 0) {
            return null;
        }
        this.cache = this.cache.filter((item) => !keys.includes(item[this.key]));
    }

    /**
     * Deleted an item with passed key
     * @param {*} key
     * @returns {Promise<void>}
     */
    async delete(key) {
        const index = await this.getIndex(key);
        if (index !== -1) {
            this.cache.splice(index, 1);
        }
    }

    /**
     * Return all records
     * @return {Promise<[]>}
     */
    async getAll() {
        return this.cache;
    }

    async getAllKeys() {
        return this.cache.map((o) => o[this.key]);
    }

    /**
     * Remove all cache items whose _created entry is less than passed timestamp
     * @param {number} ts
     * @param {number|null} limit
     * @return {Promise<number>}
     */
    async deleteBeforeTs(ts, limit = null) {
        if (!this.createdIndex) {
            throw new Error("Trying to deleteBeforeTs but createdIndex is false");
        }

        // Keep any items whose created_at timestamp is greater than the ttl ts (i.e. remove old items)
        let count = 0;
        this.cache = this.cache.filter((item) => {
            // If we've already filtered out the limit, include all the rest
            if (limit !== null && count >= limit) {
                return true;
            }

            if (item._created < ts) {
                count++;
                return false;
            }
            return true;
        });

        return count;
    }

    /**
     * @param {number|null} limit
     * @return {Promise<number>}
     */
    async deleteByTtl(limit = null) {
        return this.deleteBeforeTs(utcTs() - this.ttl, limit);
    }
}
